:root {
  --msoe-red: #C5050C;
  --msoe-red-hover: #b0050c;
}

.table-container {
  max-height: 80vh;
  overflow: auto;
}

.fix-header {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 25vw;
  background: white;
}

.stats-tbody tr:nth-child(odd) {
  background-color: rgba(233, 233, 233);
}
.season-select {
  display: inline-block;
  width: 150px;
  margin: 0 auto 0 0;  /* Margin to keep it left-aligned */
}
.season-select-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align the dropdown to the left */
}

.fix-column-even {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: rgb(233, 233, 233);
}

.fix-column-odd {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.right-text {
  text-align: right;
}

.person-icon {
  font-size: large;
  color: black;
  margin-right: 1rem;
}

.person-icon:hover {
  color: rgb(113, 113, 113);
}

select, input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.password-container {
  margin: 10px 10px 0 10px;
}

.password, .wide-button {
  width: 100%;
}

.password-entry {
  border: 1px solid black;
}

.number-teams-generate {
  font-size: 16px;
  background-color: white;
  border: 1px solid black;
  width: 50%;
  padding: 0;
  margin: 0 10px;
  font-family: 'Poppins', sans-serif;
}

select {
  min-width: 2rem;
}

.center-text {
  text-align: center;
}

.team-1-score {
  text-align: right;
}

.sort-column {
  cursor: pointer;
}

.sorted {
  background-color: rgb(223, 223, 223);
}

.sort-column:hover {
  background-color: rgb(223, 223, 223);
}

.practice-card {
  background-color: rgb(230, 230, 230);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.game-card {
  background-color: whitesmoke;
  border-radius: 10px;
  font-size: 14px;
}

tr.player-in-0 {
  background-color: #d1f0da;
}

tr.player-in-1 {
  background-color: #bce4c6;
}

tr.player-out-0 {
  background-color: #f2d9d8;
}

.player-team-out {
  background-color: #f2d9d885;
}

tr.player-out-1 {
  background-color: #ebc5c4;
}


.stats {
  overflow: scroll;
}

.primary-div {
  background-color: var(--msoe-red);
}

.secondary-div {
  background-color: black;
}

.nav-bar {
  max-height: 100px;
}

.nav-bar-home {
  height: 100%;
}

.nav-bar-home img {
  display: block;
  width: 20%;
  margin: auto;
}

  .nav-bar-btn, .nav-bar-btn-selected {
    flex: 0 0 auto;
    min-width: 20%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: x-large;
    padding: 10px;
    white-space: nowrap;
  }

.nav-bar-btn-small {
  min-height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: x-large;
  margin: 0 10px;
}

.nav-bar-btn {
  border-bottom: 3px solid var(--msoe-red);
}

.nav-bar-btn-selected {
  font-weight: bold;
  border-bottom: 3px solid black;
}

.nav-bar-btn:hover, .nav-bar-btn-selected:hover {
  background-color: var(--msoe-red-hover);
  font-weight: bold;
  border-bottom: 3px solid black;
}

.App {
  text-align: center;
}

@media (max-width: 1224px) {
  .App {
    margin: 3% 5%;
  }
}

@media (min-width: 1225px) {
  .App {
    margin: 5% 20%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App.css */

/* Add a new CSS class for the custom checkbox size */
.custom-checkbox {
  transform: scale(1.5); /* Adjust the scale factor as needed */
}


.form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
