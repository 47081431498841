@import '@fortawesome/fontawesome-free/css/all.css';

.App {
  justify-content: center;
  text-align: center;
}

.bar-title {
  font-size: 18px;
  font-weight: bold;
  left: 50%;
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.board-page-container {
  transform: translateX(-2%);
  text-align: center;
  align-items: center;
}

.bottom-bar {
  align-items: center;
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 70px;
  margin: auto;
  padding: 10px;
  position: relative;
  width: 800px;
  justify-content: center; /* Center horizontally */
}
.buttons-container button {
  border: black;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  padding: 10px 20px;
  align-items: center;
}

.canvas-container {
  align-items: flex-start;
  display: flex;
  height: 600px;
  margin: 0 auto;
  position: relative;
  width: 800px;
  cursor: auto;

}

canvas {
  border: 1px solid #ccc;
  height: 100%;
  width: 100%;

}

.category {
  margin-bottom: 10px;
}

.category-players {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.category-title {
  background-color: #ccc;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.clean-button {
  background-color: #a99b9b11;
  color: rgb(28, 4, 4);
}

.clean-button:hover {
  background-color: #919191;
}

.coaches-button-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.coaches-button {
  background: none;
  border: none;
  border-bottom: 5px solid rgb(0, 0, 0);
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0;
}
.coaches-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}


.coaches-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.delete-icon,
.draw-icon,
 .drawCurved-icon,
 .textBox-icon {
  padding-top: 25px;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px; /* Add some horizontal spacing between icons */
}

.delete-icon.active {
  color: red;
}

.draw-icon.active,
.drawCurved-icon.active,
.textBox-icon.active {
  color: rgb(13, 185, 88);
}

.player {
  align-items: center;
  background: linear-gradient(to bottom, #f2f2f2, #d9d9d9); /* Gradient background */
  border: 1px solid black;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow */
  color: black;
  cursor: move;
  display: flex;
  font-family: Arial, sans-serif;
  font-size: 10px;
  height: 25px;
  justify-content: center;
  margin: 0 5px; /* Reduce the margin between players */
  margin-bottom: 5px;
  padding: 0 5px; /* Padding for a more balanced look */
  width: 65px;
  line-height: 1; /* Reduce the line height to minimize the space between lines */
  text-align: center; /* Center-align the text inside the box */
}


.player.coach {
  background: rgb(237, 38, 38);
}

.players-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.players-title {
  border-bottom: 5px solid black;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
}

.overlay-canvas {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  border: 1px solid #ccc; /* Optional: for visual reference */
  width: 50%;
  height: 100%;
}
.save-pdf-button, .save-png-button {
  background-color: #0da70f;
  color: white;
}

.save-pdf-button:hover, .save-png-button:hover {
  background-color: #00b327;
}


